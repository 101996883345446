
#root {
  background-color: #f5f5f5;
  display: flex;
  justify-content: center;
  min-height: 100vh;
  min-width: 100vw;
  overflow-x: hidden;
  width: 100vw;
}
html,
body {
  background-color: grey !important;
  color: #3a3a3a;
  font-family:Helvetica, Arial, sans-serif !important;
}

.App {
  margin: auto;
  min-height: 100vh;
  height: 100%;
  width: 100%;
  max-width: 500px;
  box-shadow: 0px 1px 15px 10px #d8d8d8;
}
.flip-card {
  background-color: transparent;
  perspective: 1000px; 
  width: 100%; 
  height: 600px;
  cursor: pointer;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: left;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.flip-card-front, .flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  border-radius: 1rem; 
}

.flip-card-front {
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: left;
}

.flip-card-back {
  background-color: white;
  transform: rotateY(180deg);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: left;
}

.flip-card.flipped .flip-card-inner {
  transform: rotateY(180deg);
}
