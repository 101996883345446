@tailwind base;
@tailwind components;
@tailwind utilities;

.title {
  @apply bg-lightPing text-red-500 p-2 rounded-md space-y-2 mt-2 text-[0.675rem];
}
.button
{
  @apply text-white rounded-md p-2 hover:translate-y-[1px]
} 
.loader {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: inline-block;
  border-top: 4px solid #FFF;
  border-right: 4px solid transparent;
  box-sizing: border-box;
  animation: rotation 0.6s linear infinite;
}
.loader::after {
  content: '';  
  box-sizing: border-box;
  position: absolute;
  left: 0;
  top: 0;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border-bottom: 4px solid #FF3D00;
  border-left: 4px solid transparent;
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} 